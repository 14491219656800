import { StrictMode } from "react";
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import reportWebVitals from './reportWebVitals';
import HomePage from './pages/home/HomePage';
import Terms from "./pages/Terms";
import AvailableUpdates from "./pages/AvailableUpdates";
import BlogPage from "./pages/BlogPage";
import AppleAppSiteAssociation from "./pages/AppleAppSiteAssociation";
import PageNotFound from "./pages/PageNotFound";
import BlogDetails from "./pages/BlogDetails";
import DownloadPage from './pages/DownloadPage';
import AboutUs from "./pages/AboutUs";
import PaymentStatus from "./pages/PaymentStatus";
import EmailImages from "./pages/EmaiImages";
import QueryClientProviderWrapper from "./lib/queryClient";
import Support from "./pages/Support";
import CampaignDownloads from "./pages/CampaignDownloads";
import Previews from "./pages/Previews";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const router = createBrowserRouter([
  {
    path: "/",
    element: <HomePage/>,
  },

  // {
  //   path: "/privacy",
  //   element: <Privacy/>
  // },

  {
    path: "/terms",
    element: <Terms/>
  },

  {
    path: "/app",
    element: <App/>
  },

  {
    path: "/updates",
    element: <AvailableUpdates/>
  },

  {
    path: "/blog",
    element: <BlogPage/>
  },

  {
    path: "/blog/:id",
    element: <BlogDetails/>
  },

  {
    path: "*",
    element: <PageNotFound/>
  },

  {
    path: "/download",
    element: <DownloadPage/>
  },

  {
    path: "/campaign",
    element: <CampaignDownloads/>
  },

  {
    path: "/about",
    element: <AboutUs/>
  },

  {
      path: "/apple-app-site-association",
      element: <AppleAppSiteAssociation/>
  },

  {
    path: "/email-notification/test",
    element: <EmailImages/>
  },

  {
    path: "/transaction/verify/rave",
    element: <PaymentStatus/>
  },

  {
    path: "/support",
    element: <Support/>
  },

  {
    path: "/payment/preview",
    element: <Previews/>
  }

]);

root.render(
  <StrictMode>
    <QueryClientProviderWrapper>
      <RouterProvider router={router} />
    </QueryClientProviderWrapper>
  </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
